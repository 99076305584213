/* PrivacyPolicy.module.css */

.container {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  color: #333;
  margin-bottom: 20px;
}

.sectionTitle {
  color: #0056b3;
}

.text {
  color: #666;
  line-height: 1.6;
}

.list {
  margin-left: 20px;
}
